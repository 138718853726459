import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoints } from "../endpoints";
import { ServiceResponse, User } from "../types";
import Qs from "qs";

interface UserListQueryParams { 
    page: number,
    pageSize: number,
    searchTerm: string,
    roleId: string,
    groupId: string,
    sortColumn: string, 
    sortDirection: string
}

const useGetAllUsers = (params: UserListQueryParams) => {
    return useQuery(
        ["users", { ...params }],
        async () => {
            let usersResponse: ServiceResponse<User[]> | null = null;
            try {
                const token = localStorage.getItem("token");
                const authtype = localStorage.getItem("authtype");
                if (token && authtype) {
                    const options = {
                        headers: {
                            authtype,
                            Authorization: `Bearer ${token}`,
                        },
                        params,
                        paramsSerializer: (params: any) => {
                            return Qs.stringify(params);
                        },
                    };
                    const response = await axios.get<ServiceResponse<User[]>>(endpoints.users, options);
                    usersResponse = response.data;
                }
            } catch (e) {
            } finally {
                return usersResponse;
            }
        },
        {
            staleTime: 1000 * 60 * 60 * 24, // 24 hours in client cache
        }
    );
};

export default useGetAllUsers;
