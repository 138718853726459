import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const usePredictionFeedback = (id: string) => {
    return useQuery(["feedback", "predictions", id], async () => {
        let result: any[] = [];
        try {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                const res = await axios.get('/api/v1/feedback/predictions/plot?modelId=' + id, options)
                result = res.data.data
            }

        } catch (e: any) {
        } finally {
            return result;
        }
    })
}

export default usePredictionFeedback
