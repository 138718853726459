import { createSlice } from "@reduxjs/toolkit"
import store from "../store"

export type FeedbackResponse = {
  severity: 'success' | 'info' | 'error' | 'warning';
  title: string;
  message: string;
  data: any;
  action?: string;
};

export type ResponseState = {
    response: FeedbackResponse | null
}

const initialState: ResponseState = {
  response: null
};

export const responseSlice = createSlice({
  name: "response",
  initialState,
  reducers: {
    setResponse: (state, action) => {
      state.response = action.payload
    }
  }
})

export const { setResponse } = responseSlice.actions

export type RootState = ReturnType<typeof store.getState>

export const selectResponse = (state: RootState) => state.response.response

export default responseSlice.reducer
