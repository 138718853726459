import React, { useEffect, useState } from 'react'
import { AnalyticsProps } from '../AnalyticsSteps'
import { useAnalyticPreview } from '../../../features/mutations'
import { JSONTree } from 'react-json-tree'
import { JSONTreeTheme } from '../../../Utils/constants'
import { useLayout, useResponse } from '../../../Store/Hooks'
import { defaultParams } from './ReverseMLUtils'
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import Checkbox from '../../Checkbox'

const RedshiftReadParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {
    const { setWarningResponse } = useResponse()
    const previewMutation = useAnalyticPreview()
    const { configEditing } = useLayout()
    const [connectorName, setConnectorName] = useState('')
    const [previewReady, setPreviewReady] = useState(false)
    const [scheduled, setScheduled] = useState(false)
    const [params, setParams] = useState({ ...defaultParams, connectorType: 'redshift', connectorAction: 'read', authenticationType: 'UPCredentials' })
    const [passwordEditable, setPasswordEditable] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState({
        password: '',
    })

    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                const _params = _model.config.params.analytics[stepIndex].params
                setConnectorName(_model.config.params.analytics[stepIndex].label)
                setParams({
                    ...params,
                    ..._params,
                })
                if (_params.schedule?.length > 0) {
                    setScheduled(true)
                }
            }
        }
    }, [stepIndex])

    useEffect(() => {
        if (
            (params.connectorType === 'redshift' && params.authenticationType === 'arn' &&
            params.arn.length > 0 &&
            params.dbName.length > 0 &&
            params.dbUser.length > 0 &&
            params.clusterName.length > 0 &&
            params.query.length > 0) ||
            (params.connectorType === 'redshift' && params.authenticationType === 'UPCredentials' &&
            params.dbName.length > 0 &&
            params.dbUser.length > 0 &&
            params.host.length > 0 &&
            params.port.length > 0 &&
            params.password.length > 0 &&
            params.query.length > 0)
        ) {
            setPreviewReady(true)
        } else {
            setPreviewReady(false)
        }
    }, [params])

    if (!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const handleConfirmPasswordFieldOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword({ ...confirmPassword, [event.target.name]: event.target.value })
    }

    const handlePasswordEdit = () => {
        setPasswordEditable(true)
        let _params = { ...params }
        _params.password = ''
        setParams({
            ..._params,
        })
    }

    const fetchPreviewData = (e: any) => {
        e.preventDefault()
        if (previewReady) {
            previewMutation.mutate({
                step: { className: 'ReverseMLWorkflow', params },
            })
        }
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onScheduledChange = (e: any) => {
        if (!e.target.checked) {
            setParams({ ...params, schedule: '' })
        }
        setScheduled(!scheduled)
    }

    const handleScheduleOnChange = (value: string) => {
        setParams({ ...params, schedule: value })
    }

    const onSubmit = () => {
        let _params = { ...params }
        if (_params.connectorType === 'redshift' && _params.authenticationType === 'arn' &&
            (
                connectorName.length === 0 ||
                _params.arn.length === 0 ||
                _params.dbName.length === 0 ||
                _params.dbUser.length === 0 ||
                _params.clusterName.length === 0 ||
                _params.query.length === 0)) {
                setWarningResponse(
                    'Required Fields',
                    `Please complete all required fields: ${[
                        'ARN',
                        'DB Name',
                        'DB User',
                        'Cluster',
                        'Query'
                    ].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
                )
                return
        } else if (_params.connectorType === 'redshift' && _params.authenticationType === 'UPCredentials' &&
            (
                connectorName.length === 0 ||
                _params.dbName.length === 0 ||
                _params.dbUser.length === 0 ||
                _params.host.length === 0 ||
                _params.port.length === 0 ||
                _params.password.length === 0 ||
                _params.query.length === 0)) {
                setWarningResponse(
                    'Required Fields',
                    `Please complete all required fields: ${[
                        'Connector Name',
                        'DB Name',
                        'DB User',
                        'Host',
                        'Port',
                        'Password',
                        'Query',
                    ].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
                )
                return
        }
        if (
            (_model.id === 'new' && _params.password !== confirmPassword.password) ||
            (_model.id !== 'new' && passwordEditable && _params.password !== confirmPassword.password)
        ) {
            setWarningResponse(
                'Password Validation Error',
                `Ensure the following fields match: ${['Password', 'Confirm Password'].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
            )
            return
        }

        if (_params.password === confirmPassword.password) {
            _params.password = params.password
        } else {
            _params.password = ''
        }
        onSaveChanges(connectorName, _params)
    }

    return (
        <div className='flex flex-col gap-4'>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                />
            </div>
            <hr className='border-ai-200' />
            <div>
                <p className='text-[13px] text-ai'>Authentication Type*</p>
                <div className='flex gap-5'>
                    <div className='relative flex items-center'>
                        <input
                            className='radio-parent peer'
                            name='authenticationType'
                            id='UPCredentials'
                            type='radio'
                            value='UPCredentials'
                            checked={params.authenticationType === 'UPCredentials'}
                            onChange={handleFieldsOnChange}
                        />
                        <div className='radio' />
                        <label htmlFor='UPCredentials' className='cursor-pointer truncate pl-3'>
                            User Credentials
                        </label>
                    </div>

                    <div className='relative flex items-center'>
                        <input
                            className='radio-parent peer'
                            name='authenticationType'
                            id='connectedApp'
                            type='radio'
                            value='arn'
                            checked={params.authenticationType === 'arn'}
                            onChange={handleFieldsOnChange}
                        />
                        <div className='radio' />
                        <label htmlFor='connectedApp' className='cursor-pointer truncate pl-3'>
                            ARN
                        </label>
                    </div>
                </div>
            </div>
            <div className='flex-1'>
                <label className='flex-1 px-2 text-[13px] text-ai'>DB Name</label>
                <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.dbName ?? ''} name='dbName' />
            </div>
            <div className='flex-1'>
                <label className='flex-1 px-2 text-[13px] text-ai'>DB User</label>
                <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.dbUser ?? ''} name='dbUser' />
            </div>
            {params.authenticationType === 'arn' && (
                <>
                    <div className='flex gap-2'>
                        <div>
                            <label className='px-2 text-[13px] text-ai'>ARN</label>
                            <input
                                className='input w-full'
                                type={'text'}
                                onChange={handleFieldsOnChange}
                                value={params.arn ?? ''}
                                name='arn'
                            />
                        </div>
                        <div className='flex-1'>
                            <label className='flex-1 px-2 text-[13px] text-ai'>Cluster Name</label>
                            <input
                                className='input w-full'
                                type={'text'}
                                onChange={handleFieldsOnChange}
                                value={params.clusterName ?? ''}
                                name='clusterName'
                            />
                        </div>
                    </div>
                </>
            )}
            {params.authenticationType === 'UPCredentials' && (
                <>
                    <div className='grid grid-cols-6 gap-2'>
                        <div className='relative top-1 col-span-full xl:col-span-3'>
                            <div className='flex justify-between'>
                                <label className='px-2 text-[13px] text-ai'>Password*</label>
                                {_model.id !== 'new' && !passwordEditable && (
                                    <label
                                        className='cursor-pointer px-2 text-[13px] text-ai-300 transition hover:text-ai hover:underline'
                                        onClick={handlePasswordEdit}
                                    >
                                        Edit
                                    </label>
                                )}
                            </div>
                            <input
                                className='input w-full'
                                type={'password'}
                                onChange={e => {
                                    if (params.password.length < 1 && confirmPassword.password.length > 0) {
                                        setConfirmPassword({ password: '' })
                                    }
                                    handleFieldsOnChange(e)
                                }}
                                value={params.password ?? ''}
                                name='password'
                                disabled={_model.id !== 'new' && !passwordEditable}
                            />
                        </div>
                        <div className='col-span-full xl:col-span-3'>
                            <label className='px-2 text-[13px] text-ai'>Confirm Password*</label>
                            <input
                                className='input w-full'
                                type={'password'}
                                onChange={handleConfirmPasswordFieldOnChange}
                                value={params.password ? confirmPassword.password : ''}
                                disabled={params.password.length < 1 || (_model.id !== 'new' && !passwordEditable)}
                                name='password'
                            />
                        </div>
                    </div>
                    <div className='flex gap-2'>
                        <div className='flex-1 '>
                            <label className='px-2 text-[13px] text-ai'>Host*</label>
                            <input
                                className='input w-full'
                                type={'text'}
                                onChange={handleFieldsOnChange}
                                value={params.host ?? ''}
                                name='host'
                            />
                        </div>
                        <div className='flex-1 '>
                            <label className='flex-1 px-2 text-[13px] text-ai'>Port*</label>
                            <input
                                className='input w-full'
                                type={'text'}
                                onChange={handleFieldsOnChange}
                                value={params.port ?? ''}
                                name='port'
                            />
                        </div>
                    </div>
                </>
            )}
            <div>
                <label className='px-2 text-[13px] text-ai'>Query</label>
                <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.query ?? ''} name='query' />
            </div>
            <div>
                <button disabled={!previewReady} className='btn-secondary py-1 px-2 text-sm' onClick={fetchPreviewData}>
                    Fetch Preview Data
                </button>
            </div>
            <hr className='border-ai-200' />
            <div className='flex flex-1 gap-2'>
                <Checkbox checked={scheduled} onChange={onScheduledChange} />
                <label className='block px-2 text-ai'>Schedule ETL?</label>
            </div>
            {scheduled && (
                <div className='w-full'>
                    <Cron value={params.schedule} setValue={handleScheduleOnChange} />
                </div>
            )}
            <hr className='border-ai-200' />
            <div className='flex w-full flex-col items-center justify-center'>
                {previewMutation.isLoading && <p className='font-[20px] text-ai'>Fetching data preview...</p>}
                {previewMutation.isError && (
                    <p className='font-[20px] text-ai'>Could not retrieve preview of the data - Review connector parameters</p>
                )}
                {!previewMutation.isLoading && configEditing.analyticsPreview && (
                    <div className='w-full flex-1 bg-white p-4'>
                        <p className='font-bold text-ai-700'>Data Sample Preview: </p>
                        <JSONTree
                            shouldExpandNode={() => {
                                return true
                            }}
                            theme={JSONTreeTheme}
                            data={configEditing.analyticsPreview ?? {}}
                        />
                    </div>
                )}
            </div>
            {/* footer */}
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button className='btn-secondary border-none bg-transparent text-[#FF0000]' onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default RedshiftReadParams
