import React from 'react'
import { PostprocessingProps } from './PostprocessingSteps'

const RegressionClass: React.FC<PostprocessingProps> = ({ _model, onSaveChanges, onRemoveClass }) => {

    const [params, setParams] = React.useState({
        min: 0,
        max: 0
    })
    
    React.useEffect(() => {
        if (_model && _model.config.params.postprocessingSteps && _model.config.params.postprocessingSteps?.length > 0 && _model.config.params.postprocessingSteps[0].className === "Regression") {
            setParams({ ..._model.config.params.postprocessingSteps[0].params })
        }
    }, [_model])
    
    if(!_model) {
        return null
    }
    
    const onSubmit = () => {
        onSaveChanges(params)
    }

    const onParamsChange = (e: any) => {
        setParams({
            ...params,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="flex flex-col gap-4">
            <p className='text-ai-700 font-bold'>
                Regression
            </p>
            <div className='flex gap-2'>
                <div className='flex-1'>
                    <label className="text-ai text-[13px] px-2">Min</label>
                    <input className="input w-full" onChange={onParamsChange} value={params.min ?? ''} placeholder="" name="min" />
                </div>
                <div className='flex-1'>
                    <label className="text-ai text-[13px] px-2">Max</label>
                    <input className="input w-full" onChange={onParamsChange} value={params.max ?? ''} placeholder="" name="max" />
                </div>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]" disabled={!_model.config.params.harvestingSteps || _model.config.params.harvestingSteps.length === 0} onClick={onRemoveClass}>Remove Class</button>
            </div>
        </div>
    )
}

export default RegressionClass

