import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useModelFeedback = (id: string) => {
    return useQuery(["feedback", "models", id], async () => {
        let url = `/api/v1/feedback/models?page=1&pageSize=15&modelId=${id}`;
        try {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                const res = await axios.get(url, options);
                if (res?.data?.data?.questionCount) {
                    return res?.data?.data?.questionCount
                }
            }
        } catch {
            return []
        }
    }, {
        enabled: !!id && id.length > 0
    })

}

export default useModelFeedback
