import React from 'react'
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom'
import { ROUTER_PATHS } from '../../Utils/constants'
import { FileDownloadOutlined, FilterListOutlined, SearchOutlined } from '@mui/icons-material'
import useRegistry from '../../features/queries/useRegistry'
import RegistryList from './RegistryList'
import ContentviewMessage from '../../Components/ContentviewMessage'
import DashboardLayout from '../../Layouts/DashboardLayout'
import Dropdown from '../../Components/Dropdown'

let searchDelay: NodeJS.Timeout | null = null

interface RegistryFiltersProps {
    page?: string,
    pageSize?: string,
    searchOnChange?: () => void,
    sortOnChange?: () => void
}

const RegistryFilters: React.FC<RegistryFiltersProps> = ({ searchOnChange, sortOnChange, page, pageSize }) => {

    const navigate = useNavigate()
    const [search, setSearch] = React.useState('')
    const [searchBy, setSearchBy] = React.useState('name')
    const [sortKey, setSortKey] = React.useState('createdAt')
    const [sortOrder, setSortOrder] = React.useState('asc')
    const [openSorts, setOpenSorts] = React.useState(false)
    const registryQuery = useRegistry({
        stage: ['staging', 'production'],
        sortKey,
        sortOrder,
        [searchBy]: search
    })

    const models = registryQuery.data

    if (registryQuery.isLoading || !models) {
        return null
    }

    const handleSearchByChange = (e: any) => {
        setSearchBy(e.target.value)
    }

    const onSortKeyChange = (e: any) => {
        setSortKey(e.target.value)
        if (sortOnChange) {
            sortOnChange()
        }
    }

    const onSortOrderChange = (e: any) => {
        setSortOrder(e.target.value)
        if (sortOnChange) {
            sortOnChange()
        }
    }

    const onNewModel = () => {
        navigate(ROUTER_PATHS.create)
    }

    return (
        <DashboardLayout title="Models" subtitle="Public ready AIR models">
            <div className='p-6'>
                <div
                    className={`mb-6 flex flex-col space-y-4 rounded-md border border-ai-200 bg-white p-3 text-sm transition-[height] duration-500 ease-in-out ${
                        openSorts ? "h-[10rem] lg:h-[8.5rem]" : "h-[4.1rem]"
                    } `}>
                    <div className="flex items-center">
                        <div className="flex flex-1 space-x-4">
                            <div className="flex max-h-10 w-full items-center rounded-md border border-ai-200 p-2 hover:border-ai focus:border-ai-400 lg:max-w-sm lg:flex-1">
                                <SearchOutlined className="text-ai" />
                                <input
                                    className="w-full px-2 focus:outline-none"
                                    placeholder="Search model"
                                    onChange={(e) => {
                                        if (searchOnChange) {
                                            searchOnChange();
                                        }
                                        if (searchDelay) {
                                            clearTimeout(searchDelay);
                                        }
                                        searchDelay = setTimeout(() => setSearch(e.target.value), 500);
                                    }}
                                />
                            </div>
                            <div>
                                <Dropdown
                                    items={["name", "author"]}
                                    selectedIndex={["name", "author"].indexOf(searchBy)}
                                    handleDropdownChange={handleSearchByChange}/>
                            </div>

                            <button
                                className="flex max-h-10 min-w-max items-center space-x-2 rounded-md border border-ai-200 p-2  text-ai lg:max-w-[7rem] lg:pr-3"
                                title="Sort By"
                                onClick={() => {
                                    setOpenSorts(!openSorts);
                                }}>
                                <FilterListOutlined className="lg:mr-2" />
                                <p className="hidden md:inline-block text-black">Sort By</p>
                            </button>
                        </div>

                        <button className="btn-filter ml-4" onClick={onNewModel} title="Import Air Model">
                            <FileDownloadOutlined className="lg:mr-2" />
                            <p className="hidden lg:inline-block">Import AIR Model</p>
                        </button>
                    </div>

                    {openSorts && (
                        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.25, delay: 0.25 }} className="space-y-2 pb-2 text-black">
                            <p className="">Sort by</p>
                            <div className="flex flex-col space-y-2 lg:flex-row lg:items-center lg:space-x-8 lg:space-y-0">
                                <div className="flex flex-1 space-x-3">
                                    <div className="relative flex items-center">
                                        <input
                                            className="radio-parent peer"
                                            name="group1"
                                            type="radio"
                                            value="name"
                                            checked={sortKey === "name"}
                                            onChange={(e) => {
                                                onSortKeyChange(e);
                                            }}
                                        />
                                        <div className="radio" />
                                        <label htmlFor="" className="pl-3 ">
                                            Name
                                        </label>
                                    </div>
                                    <div className="relative flex items-center">
                                        <input
                                            className="radio-parent peer"
                                            name="group1"
                                            type="radio"
                                            value="author"
                                            checked={sortKey === "author"}
                                            onChange={(e) => {
                                                onSortKeyChange(e);
                                            }}
                                        />
                                        <div className="radio" />
                                        <label htmlFor="" className="pl-3">
                                            Author
                                        </label>
                                    </div>
                                    <div className="relative flex items-center">
                                        <input
                                            className="radio-parent peer"
                                            name="group1"
                                            type="radio"
                                            value="createdAt"
                                            checked={sortKey === "createdAt"}
                                            onChange={(e) => {
                                                onSortKeyChange(e);
                                            }}
                                        />
                                        <div className="radio" />
                                        <label htmlFor="" className="pl-3">
                                            Upload
                                        </label>
                                    </div>
                                    <div className="relative flex items-center">
                                        <input
                                            className="radio-parent peer"
                                            name="group1"
                                            type="radio"
                                            value="modifiedAt"
                                            checked={sortKey === "modifiedAt"}
                                            onChange={(e) => {
                                                onSortKeyChange(e);
                                            }}
                                        />
                                        <div className="radio" />
                                        <label htmlFor="" className="pl-3">
                                            Last Updated
                                        </label>
                                    </div>
                                </div>

                                <div className="flex space-x-3">
                                    <div className="relative flex items-center">
                                        <input
                                            className="radio-parent peer"
                                            name="group2"
                                            type="radio"
                                            value="asc"
                                            checked={sortOrder === "asc"}
                                            onChange={(e) => {
                                                onSortOrderChange(e);
                                            }}
                                        />
                                        <div className="radio" />
                                        <label htmlFor="" className="pl-3">
                                            Ascending
                                        </label>
                                    </div>
                                    <div className="relative flex items-center">
                                        <input
                                            className="radio-parent peer"
                                            name="group2"
                                            type="radio"
                                            value="desc"
                                            checked={sortOrder === "desc"}
                                            onChange={(e) => {
                                                onSortOrderChange(e);
                                            }}
                                        />
                                        <div className="radio " />
                                        <label htmlFor="" className="pl-3">
                                            Descending
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </div>
                <RegistryList registry={models} />
                {
                    registryQuery.isLoading &&
                    <ContentviewMessage title="Fetching model registry..." message="" />
                }
                {
                    !registryQuery.isLoading && registryQuery.isFetched && (!models || models.length === 0) &&
                    <ContentviewMessage title="No models to show" message="Either no results were found given the filters or no models currently available." />
                }
            </div>
        </DashboardLayout>
    )
}

export default RegistryFilters