import React from "react";
import { RenderingProps } from "./RenderingSteps";
import BasicChartRenderingParams from "./BasicChartRenderingParams";
import { getContainerIds } from "../../Utils/utils";
import Dropdown from "../Dropdown";
import ContentviewMessage from "../ContentviewMessage";
import StyleParams from "./StyleParams";
import { useResponse } from "../../Store/Hooks";

const DoughnutChartRenderingParams: React.FC<RenderingProps> = ({
    _model,
    stepIndex,
    onSaveChanges,
    onCancel,
}) => {

    const { setWarningResponse } = useResponse()
    const [params, setParams] = React.useState({
        id: "",
        chartName: "",
        containerId: "",
        yOffset: "",
        xOffset: "",
        height: "",
        width: "",
        displayLegend: true,
        legendIcon: 'circle',
        datasource: [] as any[],
        style: {
            container: {
                border: "none",
                margin: "0",
                padding: "8px",
                background: "#ffffff",
                borderRadius: "0",
            }
        }
    });
    const [containerIds, setContainerIds] = React.useState<string[]>([]);
    const onContainerSelect = (event: any) => {
        const index = Number(event.target.value)
        setParams({...params, containerId: containerIds[index]});
    };

    React.useEffect(() => {
        if(_model.config.params.renderingSteps) {
            setContainerIds(getContainerIds(_model))
            if(stepIndex > -1) {
                setParams({...params, ..._model.config.params.renderingSteps[stepIndex].params})
            }
        }
    }, [stepIndex])

    if (!_model) {
        return null;
    }

    const onAddKey = (column: string) => {
        let _datasource = [...params.datasource, {
            predictionNameValue: column,
            predictionNameKey: 'name',
            predictionValueKey: 'value',
            labelsKey: null,
            labels: null,
            consolidateRows: false,
            group: false,
            frequency: false,
            legendIcon: 'circle'
        }]
        setParams({
            ...params,
            datasource: _datasource
        })
    };

    const onRemoveKey = (index: number) => {
        let _datasource = [...params.datasource];
        _datasource.splice(index, 1);
        setParams({
            ...params,
            datasource: _datasource
        })
    };

    const onDatasourceEdit = (datasource: any[]) => {
        setParams({...params, datasource})
    }

    const handleFieldsOnChange = (event: any) => {
        if(event.target.name === 'displayLegend') {
            // Handle switch
            setParams({ ...params, [event.target.name]: event.target.checked });
        } else {
            setParams({ ...params, [event.target.name]: event.target.value });
        }
    };

    const handleStyleChange = (element: any, attribute: any, value: string | null) => {
        // Fixes issues with read-only errors
        let _p: any = {
            ...params,
            style: {
                ...params.style,
                [element]: {
                    ...params.style[element as keyof typeof params.style]
                }
            }
        }
        if(value !== null) {
            _p.style[element][attribute] = value
        } else {
            delete _p.style[element][attribute]
        }
        setParams(_p)
    }

    const onSubmit = () => {
        if(params.id.length === 0 ||
            params.chartName.length === 0 ||
            params.height.length === 0 ||
            params.width.length === 0 ||
            params.datasource.length === 0) {
                setWarningResponse('Required Fields', `Please complete all required fields: ${["ID", "Chart name", "Height", "Width", "Data source"].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`)
                return
        }
        if(params.containerId.length === 0 &&
            (params.yOffset.length === 0 || params.xOffset.length === 0)) {
                setWarningResponse('Required Fields', `If no container was specified, please fill out the following fields: ${["X Offset", "Y Offset"].join(', ')}`)
                return
        }
        onSaveChanges(params);
    };

    return (
        <div className="flex flex-col gap-4">
            <p className="font-bold text-ai-700">Doughnut chart rendering</p>
            <div>
                { containerIds.length > 0 &&
                    <>
                        <label htmlFor="container-id" className="block text-ai text-[13px] px-2">Container ID</label>
                        <Dropdown selectedIndex={containerIds.indexOf(params.containerId)} placeholder="Select container" items={containerIds} handleDropdownChange={onContainerSelect}/>
                    </>
                }
                {
                    containerIds.length === 0 &&
                    <ContentviewMessage title="No Containers" message="Create containers for specific injections"/>
                }
            </div>
            <hr className="border-ai-200"/>
            <BasicChartRenderingParams
                _model={_model}
                params={{...params}}
                handleFieldsOnChange={handleFieldsOnChange}
                onAddKey={onAddKey}
                onRemoveKey={onRemoveKey}
                onDatasourceEdit={onDatasourceEdit}
            />
            <hr className="border-ai-200"/>
            <p className="text-ai-700 font-bold">Styling</p>
            <div className="w-full bg-ai-50 flex">
                <div className="flex-1">
                    <StyleParams style={params.style} onStyleChange={handleStyleChange}/>
                </div>
                <div className="flex-1 w-4/5 bg-ai-100 p-2 flex flex-col gap-2">
                    <p className="text-ai-700 font-bold">Preview</p>
                    <hr className="border-ai-200"/>
                    <div className="flex-1 flex flex-col justify-center gap-2 ">
                        <div style={{...params.style.container as any}}>
                            <p>Doughnut chart container</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    );
};

export default DoughnutChartRenderingParams;
