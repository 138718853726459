import React from "react";
import { RenderingProps } from "./RenderingSteps";
import Dropdown from "../Dropdown";
import Checkbox from "../Checkbox";
import StyleParams from "./StyleParams";
import { getAnalyticPreviewKeys, getContainerIds } from "../../Utils/utils";
import ContentviewMessage from "../ContentviewMessage";

const RemoteRenderingParams: React.FC<RenderingProps> = ({
    _model,
    stepIndex,
    onSaveChanges,
    onCancel,
}) => {
    const [containerIds, setContainerIds] = React.useState<string[]>([]);
    const [params, setParams] = React.useState({
        id: "",
        containerId: "",
        src: "",
        yOffset: "",
        xOffset: "",
        style: {
            frame: {
                border: "none",
                margin: "0",
                padding: "8px",
                background: "#ffffff",
                borderRadius: "0",
                color: "#000000",
                fontSize: '16px',
                textAlign: 'left',
                textDecoration: 'none',
                textTransform: 'none',
            }
        }
    });

    React.useEffect(() => {
        if(_model.config.params.renderingSteps) {
            setContainerIds(getContainerIds(_model))
            if (stepIndex > -1) {
                setContainerIds(getContainerIds(_model))
                setParams({...params, ..._model.config.params.renderingSteps[stepIndex].params} );
            }
        }
    }, [stepIndex]);

    if (!_model) {
        return null;
    }

    const onContainerSelect = (event: any) => {
        const index = Number(event.target.value)
        setParams({...params, containerId: containerIds[index]});
    };

    const handleFieldsOnChange = (event: any) => {
        setParams({ ...params, [event.target.name]: event.target.value });
    };

    const handleStyleChange = (element: any, attribute: any, value: string | null) => {
        // Fixes issues with read-only errors
        let _p: any = {
            ...params,
            style: {
                ...params.style,
                [element]: {
                    ...params.style[element as keyof typeof params.style]
                }
            }
        }
        if(value !== null) {
            _p.style[element][attribute] = value
        } else {
            delete _p.style[element][attribute]
        }
        setParams(_p)
    }

    const onSubmit = () => {
        onSaveChanges(params);
    };

    return (
        <div className="flex flex-col gap-4">
            <p className="font-bold text-ai-700">Remote rendering</p>
            <div>
                { containerIds.length > 0 &&
                    <>
                        <label htmlFor="container-id" className="block text-ai text-[13px] px-2">Container ID</label>
                        <Dropdown selectedIndex={containerIds.indexOf(params.containerId)} placeholder="Select container" items={containerIds} handleDropdownChange={onContainerSelect}/>
                    </>
                }
                {
                    containerIds.length === 0 &&
                    <ContentviewMessage title="No Containers" message="Create containers for specific injections"/>
                }
            </div>
            <hr className="border-ai-200"/>
            <p className="font-bold text-ai-700">Identifiers</p>
            <div> 
                <label htmlFor="element-id" className="block text-ai text-[13px] px-2">ID</label>
                <input
                    id="element-id"
                    className="input w-full"
                    name="id"
                    value={params.id}
                    placeholder="tag-id"
                    onChange={handleFieldsOnChange}
                />
            </div>
            <div> 
                <label htmlFor="element-id" className="block text-ai text-[13px] px-2">iFrame source</label>
                <input
                    className="input w-full"
                    name="src"
                    value={params.src}
                    placeholder="https://www.google.com"
                    onChange={handleFieldsOnChange}
                />
            </div>
            <hr className="border-ai-200"/>
            <p className="font-bold text-ai-700">Coordinates</p>
            <div className="flex gap-2">
                <div className="flex-1">
                    <p className="px-2 text-[13px] text-ai">X Coordinate</p>
                    <input
                        className="input w-full"
                        type="text"
                        name="xOffset"
                        placeholder="50"
                        value={params.xOffset ?? ""}
                        onChange={handleFieldsOnChange}
                    />
                    <p className="px-2 text-[13px] text-ai-800">
                        Number is represented in %
                    </p>
                </div>
                <div className="flex-1">
                    <p className="px-2 text-[13px] text-ai">Y Coordinate</p>
                    <input
                        className="input w-full"
                        type="text"
                        name="yOffset"
                        placeholder="50"
                        value={params.yOffset ?? ""}
                        onChange={handleFieldsOnChange}
                    />
                    <p className="px-2 text-[13px] text-ai-800">
                        Number is represented in %
                    </p>
                </div>
            </div>
            <hr className="border-ai-200"/>
            <p className="text-ai-700 font-bold">Styling</p>
            <div className="w-full bg-ai-50 flex">
                <div className="flex-1">
                    <StyleParams style={params.style} onStyleChange={handleStyleChange}/>
                </div>
                <div className="flex-1 w-4/5 bg-ai-100 p-2 flex flex-col gap-2">
                    <p className="text-ai-700 font-bold">Preview</p>
                    <hr className="border-ai-200"/>
                    <div className="flex-1 flex flex-col justify-center gap-2 max-w-4/5">
                        <iframe src={params.src} style={{...params.style.frame as any, maxWidth: '300px', maxHeight: '500px'}}/>
                    </div>
                </div>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    );
};

export default RemoteRenderingParams;
