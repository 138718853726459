import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useResponse } from "../../Store/Hooks"
import { ROUTER_PATHS } from "../../Utils/constants"
import { createAIRZipFromConfig } from "../../Utils/utils"
import {ModelConfigRoot} from "../types"

const useCreateModel = () => {

    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { setSuccessResponse, setWarningResponse, setErrorResponse } = useResponse()

    const uploadAirModel = useMutation({
        mutationFn: async (variables: { file: File | File[] }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                const files = Array.isArray(variables.file) ? variables.file : [variables.file]
                const result = files.map( async (_f) => {
                    const formData = new FormData()
                    formData.append('model', _f)
                    return await axios.post(`/upload/v1/models`, formData, options);
                })
                return Promise.all(result)
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workspace"])
            navigate(ROUTER_PATHS.workspace)
            setSuccessResponse("Models uploaded", '')
        },
        onError(e: any, variables, context) {
            if (e.response.status === 409) {
                setWarningResponse("Model Already Exists", 'One of the air models has a name that already exists.')
            } else {
                setErrorResponse("Unexpected Error " + e.response.status, 'Something went wrong while creating the model.')
            }
        },
    })

    const createAirModel = useMutation({
        mutationFn: async (variables: { config: ModelConfigRoot, resources: File[], rootFolder: string | null }) => {
            let airFile = null
            try {
                airFile = await createAIRZipFromConfig(variables.config, variables.resources, variables.rootFolder)
            } catch {
                setWarningResponse("Error creating .air file", 'Unexpected error occurred while bundling .air file.')
                return Promise.reject()
            }
            if (airFile) {
                const token = localStorage.getItem("token");
                const authtype = localStorage.getItem("authtype");
                if (token && authtype) {
                    const options = {
                        headers: {
                            authtype,
                            Authorization: `Bearer ${token}`,
                        }
                    };
                    const formData = new FormData()
                    formData.append('model', airFile)
                    return axios.post(`/upload/v1/models`, formData, options);
                }
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workspace"])
            setSuccessResponse("Model Created", '')
            navigate(ROUTER_PATHS.workspace)
        },
        onError(e: any, variables, context) {
            console.log("create error")
            console.log(e)
            if (e.response.status === 409) {
                setWarningResponse("Model Already Exists", 'This model already exists under the same name. Model names need to be unique.')
            } else {
                setErrorResponse("Unexpected Error " + e.response.status, 'Something went wrong while creating the model.')
            }
        },
    })

    return {
        uploadAirModel,
        createAirModel
    }
}

export default useCreateModel
