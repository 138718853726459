import { useSelector } from 'react-redux'
import store from '../store'
import { selectResponse, setResponse } from '../Slices/responseSlice'

const useResponse = () => {

  const response = useSelector(selectResponse)

  const setSuccessResponse = (title: string, message: string) => {
    store.dispatch(setResponse({ severity: 'success', title, message }))
  }

  const setWarningResponse = (title: string, message: string) => {
    store.dispatch(setResponse({ severity: 'warning', title, message }))
  }

  const setErrorResponse = (title: string, message: string) => {
    store.dispatch(setResponse({ severity: 'error', title, message }))
  }

  const removeResponse = () => {
    store.dispatch(setResponse(null))
  }
  
  return {
    response,
    removeResponse,
    setSuccessResponse,
    setWarningResponse,
    setErrorResponse
  }
}

export default useResponse