import React, { useEffect, useState } from 'react'
import { AnalyticsProps } from "./AnalyticsSteps"
import { useResponse } from '../../Store/Hooks'

const DeployedModelParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {

    const { setWarningResponse } = useResponse()
    const [connectorName, setConnectorName] = useState('')
    const [params, setParams] = useState({
        url: '',
        inputType: '',
        secret: ''
    })

    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                setParams({
                    ...params,
                    ..._model.config.params.analytics[stepIndex].params,
                })
                setConnectorName(_model.config.params.analytics[stepIndex].label)
            }
        }
    }, [stepIndex])

    if(!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onSubmit = () => {
        if (connectorName.length === 0) {
            setWarningResponse('Required Field', `Please complete all required fields: ${['Name', 'Executable Code'].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`)
            return
        }
        onSaveChanges(connectorName, { ...params })
    }

    return (
        <div className="flex flex-col gap-4">
            <p className='text-ai-700 font-bold'>
                Deployed Model
            </p>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                />
            </div>
            <div>
                <p className="text-ai text-[13px]">Input type</p>
                <div className="flex gap-5">
                    <div className="relative flex items-center">
                        <input
                            className="radio-parent peer"
                            name="inputType"
                            type="radio"
                            value="cv"
                            checked={params.inputType === "cv"}
                            onChange={handleFieldsOnChange}
                        />
                        <div className="radio" />
                        <label htmlFor="" className="pl-3 truncate">
                            CV
                        </label>
                    </div>
                    <div className="relative flex items-center">
                        <input
                            className="radio-parent peer"
                            name="inputType"
                            type="radio"
                            value="text"
                            checked={params.inputType === "text"}
                            onChange={handleFieldsOnChange}
                        />
                        <div className="radio" />
                        <label htmlFor="" className="pl-3 truncate">
                            Text
                        </label>
                    </div>
                </div>
            </div>
            <div className="flex gap-2">
                <div className="flex-1">
                    <label className="text-ai text-[13px] px-2">URL</label>
                    <input className="input w-full" type={"text"} onChange={handleFieldsOnChange} value={params.url ?? ''} placeholder="URL for fetching model result" name="url" />
                </div>
                <div className="flex-1">
                    <label className="text-ai text-[13px] px-2">Secret</label>
                    <input className="input w-full" onChange={handleFieldsOnChange} value={params.secret ?? ''} placeholder="" name="secret" />
                </div>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    )
}

export default DeployedModelParams