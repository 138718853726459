import { createSlice } from "@reduxjs/toolkit"
import { AIRModel } from "../../features/types";
import store from "../store"

export type PopupParams = {
  open: boolean;
  model: AIRModel | null;
}

export type PromotionParams = PopupParams & {
  stage: string;
}

export type DeleteParams = PopupParams & {
  redirectTo?: string;
}

export type ConfigEditingParams = {
  analyticsPreview: object | null;
}

export type LayoutState = {
  smallTabs: boolean,
  versionParams: PopupParams,
  cloneParams: PopupParams,
  deleteParams: DeleteParams,
  promotionParams: PromotionParams,
  configEditing: ConfigEditingParams
};


const initialState: LayoutState = {
  smallTabs: false,
  versionParams: {
    open: false,
    model: null
  },
  cloneParams: {
    open: false,
    model: null
  },
  deleteParams: {
    open: false,
    model: null
  },
  promotionParams: {
    open: false,
    model: null,
    stage: 'staging'
  },
  configEditing: {
    analyticsPreview: null
  }
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setSmallTabs: (state, action) => {
      state.smallTabs = action.payload
    },
    setOpenVersion: (state, action) => {
      state.versionParams = action.payload
    },
    setOpenClone: (state, action) => {
      state.cloneParams = action.payload
    },
    setOpenPromote: (state, action) => {
      state.promotionParams = action.payload
    },
    setOpenDelete: (state, action) => {
      state.deleteParams = action.payload
    },
    setConfigAnalyticPreview: (state, action) => {
      state.configEditing.analyticsPreview = action.payload
    }
  }
})

export const { setSmallTabs, setOpenClone, setOpenVersion, setOpenPromote, setOpenDelete, setConfigAnalyticPreview } = layoutSlice.actions

export type RootState = ReturnType<typeof store.getState>

export const selectSmallTabs = (state: RootState) => state.layout.smallTabs
export const selectOpenClone = (state: RootState) => state.layout.cloneParams
export const selectOpenVersion = (state: RootState) => state.layout.versionParams
export const selectPromotionParams = (state: RootState) => state.layout.promotionParams
export const selectDeleteParams = (state: RootState) => state.layout.deleteParams
export const selectConfigEditingParams = (state: RootState) => state.layout.configEditing

export default layoutSlice.reducer
