export const defaultParams = {
    dataSource: '',
    bucket: '',
    column: ' ',
    connectorType: '',
    connectorAction: '',
    fileNames: [] as string[],
    fileName: '',
    filterByColumns: [] as ColumnFilter[],
    inputType: 'text',
    soql: '',
    arn: '',
    dbName: '',
    dbUser: '',
    clusterName: '',
    query: '',
    account: '',
    password: '',
    warehouse: '',
    schema: '',
    schedule: '',
    organization: '',
    token: '',
    host: '',
    port: '',
    path: '',
    dbTable: '',
    dataMap: [] as DataMap[],
    role: '',
    clientId: '',
    clientSecret: '',
    authenticationType: '', //UPCredentials
    syncKeys: {
        source: '',
        destination: '',
    },
    overwrite: false as boolean,
}

export interface ColumnFilter {
    inputType: string
    columnName: string
    columnValue: string
}

export interface DataMap {
    input: string
    output: string
}
