import React from "react"
import { HarvesterProps } from "./HarvesterSteps"

const InputHarvesterParams: React.FC<HarvesterProps> = ({ _model, onSaveChanges, onRemoveClass }) => {

    const [params, setParams] = React.useState({
        inputType: 'text',
        maxLength: null
    })
  
    React.useEffect(() => {
        if (_model && _model.config?.params?.harvestingSteps && _model.config?.params?.harvestingSteps?.length > 0 && _model.config?.params?.harvestingSteps[0].className === 'InputHarvester' ) {
            setParams({ ..._model.config?.params?.harvestingSteps[0].params })
        }
    }, [])

    if(!_model) {
        return null
    }

    const handleHowOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onSubmit = () => {
        onSaveChanges(params)
    }

    return (
        <div className="flex flex-col gap-4">
            <p id="text-harvester-how" className="text-ai-800  text-[16px]">The type of input</p>
            <div className="relative flex items-center">
                <input
                    className="radio-parent peer"
                    name="inputType"
                    type="radio"
                    value="all"
                    checked={params.inputType === "text"}
                    onChange={handleHowOnChange}
                />
                <div className="radio" />
                <label htmlFor="" className="pl-3 truncate">
                    Text
                </label>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]" disabled={!_model.config.params.harvestingSteps || _model.config.params.harvestingSteps.length === 0} onClick={onRemoveClass}>Remove Class</button>
            </div>
        </div>
    )
}

export default InputHarvesterParams