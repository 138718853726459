import React from 'react'
import { PostprocessingProps } from './PostprocessingSteps'
import { AddCircleOutline, Remove } from '@mui/icons-material';

const ObjectDetectionClass: React.FC<PostprocessingProps> = ({ _model, onSaveChanges, onRemoveClass }) => {

    const [label, setLabel] = React.useState('')
    const [params, setParams] = React.useState({
        labelMap: [] as string[],
        threshold: '0.5',
    })
    
    React.useEffect(() => {
        if (_model && _model.config.params.postprocessingSteps && _model.config.params.postprocessingSteps?.length > 0 && _model.config.params.postprocessingSteps[0].className === "ObjectDetection") {
            setParams({ ..._model.config.params.postprocessingSteps[0].params, labelMap: _model.config.params.postprocessingSteps[0].params.labelMap.map((label: string) => { return { label } }) })
        }
    }, [_model])
    
    if(!_model) { 
        return null
    }

    const labelOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLabel(event.target.value)
    }

    const labelOnAdd = () => {
        let _rows = [...params.labelMap]
        _rows.push(label)
        setParams({
            ...params,
            labelMap: _rows
        })
        setLabel('')
    }

    const labelOnRemove = (index: number) => {
        let _rows = [...params.labelMap]
        _rows.splice(index, 1)
        setParams({
            ...params,
            labelMap: _rows
        })
    }

    const onSubmit = (e: any) => {
        const _params = { ...params }
        onSaveChanges(_params)
    }

    const onParamsChange = (e: any) => {
        setParams({
            ...params,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="flex flex-col gap-4">
            <p className='text-ai-700 font-bold'>
                Object Detection
            </p>
            <p className='text-ai-800 '>Add the labels for object detection</p>
            <div className="flex flex-col gap-3 rounded-sm border border-ai-200 bg-ai-100 p-2">
                <div className="flex items-center gap-2">
                    <div className="flex-1">
                        <label className="block px-2 text-[13px] text-ai">
                            Label
                        </label>
                        <input
                            className="input w-full"
                            type="text"
                            placeholder="Cat"
                            value={label}
                            onChange={labelOnChange}
                        />
                    </div>
                    <div>
                        <button
                            className="btn-secondary flex items-center border border-ai p-3 text-[14px] font-bold"
                            onClick={labelOnAdd}
                        >
                            <AddCircleOutline sx={{ fontSize: "16px" }} />
                            <span className="px-1">Add</span>
                        </button>
                    </div>
                </div>
                <div>
                    <table aria-label="datasources" className="border border-ai-200">
                        <thead className="border-b border-b-ai-200 bg-white text-left">
                            <tr>
                                <th className="w-1 px-4 py-2">
                                    <span className="text-[16px]  text-ai-800">
                                        #
                                    </span>
                                </th>
                                <th className="w-full">
                                    <span className="text-[16px]  text-ai-800">
                                        Value
                                    </span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="text-left">
                            {params.labelMap.length === 0 && (
                                <tr>
                                    <td colSpan={4}>
                                        <div className="w-full p-6 text-center font-[16px] text-ai-800">
                                            There are no labels yet.
                                        </div>
                                    </td>
                                </tr>
                            )}
                            
                            {params.labelMap.map((source: any, index: number) => {
                                return (
                                    <tr key={source.predictionNameKey + index} className={"bg-ai-50"}>
                                        <td className="p-4">
                                            <span className=" text-ai-800">
                                                {index + 1 + "."}
                                            </span>
                                        </td>
                                        <td>
                                            <span className=" text-ai-800">
                                                {source}
                                            </span>
                                        </td>
                                        <td>
                                            <button className="btn-icon"
                                                onClick={() =>
                                                    labelOnRemove(index)
                                                }
                                            >
                                                <Remove color="error" />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <label className="text-ai text-[13px] px-2">Threshold</label>
                <input className="input w-full" onChange={onParamsChange} value={params.threshold ?? ''} placeholder="" name="threshold" />
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]" disabled={!_model.config.params.harvestingSteps || _model.config.params.harvestingSteps.length === 0} onClick={onRemoveClass}>Remove Class</button>
            </div>
        </div>
    )
}

export default ObjectDetectionClass