export { };

declare global {
    interface Window {
        env: any
    }
}

type EnvType = {
    REACT_APP_AUTH_URL: string,
    REACT_APP_CLIENT_ID: string,
    REACT_APP_REDIRECT_URI: string,
    REACT_APP_SILENT_REDIRECT_URI: string,
    REACT_APP_LOGOFF_REDIRECT_URI: string
}

export const env: EnvType = { ...process.env, ...window.env }