import React from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import UserList from "./UserList";

const Users: React.FC<{}> = () => {

	return (
		<DashboardLayout title="Users" subtitle="No-code/low-code intelligent augmentation">
			<UserList />
		</DashboardLayout>
	);
};

export default Users;
