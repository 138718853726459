import React from "react"
import { Box, Dialog, DialogContent, DialogActions, Typography, Button } from "@mui/material"

interface ModalViewLayoutProps {
    open: boolean,
    warning?: boolean,
    title?: string,
    subtitle?: string,
    children?: React.ReactNode,
    primaryLabel?: string,
    secondaryLabel?: string
    onSubmit?: () => void,
    onClose?: () => void
}

const ModalViewLayout: React.FC<ModalViewLayoutProps> = ({ open, warning, children, title, subtitle, primaryLabel, secondaryLabel, onSubmit, onClose }) => {

    return (
        <Dialog open={open}>
            <Box sx={{
                padding: '16px'
            }}>
                {title && <Typography variant='h5' color={'primary'}>
                    {title}
                </Typography>}
                {subtitle && <Typography variant='subtitle1' sx={{ borderBottom: '1px solid #E2E2E2', paddingBottom: '32px' }}>
                    {subtitle}
                </Typography>}
            </Box>
            <DialogContent sx={{ padding: '0 16px' }}>
                {children}
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex' }}>
                    {onClose && <Button color="primary" variant="outlined" onClick={onClose} sx={{ flex: '1 0', mr: 1, textTransform: 'none', fontWeight: 'bold' }}> {secondaryLabel ?? 'Close'} </Button>}
                    {onSubmit && <Button color={warning ? 'error' : 'primary'} variant="contained" onClick={onSubmit} sx={{ flex: '1 0', fontWeight: 'bold' }}> {primaryLabel ?? 'Submit'} </Button>}
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default ModalViewLayout
