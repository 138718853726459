/* global chrome */
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { User as OidcUser } from 'oidc-client-ts'
import { useAuth } from 'react-oidc-context'
import { endpoints } from '../endpoints'
import { User } from '../types'
import { OIDC_CONFIG } from '../../Utils/constants'

function getUser() {
    const oidcStorage = localStorage.getItem(`oidc.user:${OIDC_CONFIG.authority}:${OIDC_CONFIG.client_id}`)
    if (!oidcStorage) {
        return null
    }

    return OidcUser.fromStorageString(oidcStorage)
}

const useGetCurrentUser = () => {
    const auth = useAuth()

    return useQuery({
        queryKey: ['currentUser'],
        queryFn: () => {
            const fetchUser = async () => {
                let oidcUser = getUser()
                if (!oidcUser) {
                    return Promise.reject('No OIDC User found.')
                }
                if (!oidcUser?.access_token) {
                    return Promise.reject('Missing token.')
                }
                let headers = { Authorization: `Bearer ${oidcUser?.access_token}` }
                return await axios.get<User>(endpoints.me, { headers: headers })
            }
            return fetchUser()
        },
        retry: 0,
        onError: e => {
            console.log(`[getCurrentUser] ${e}`)
            if (auth.isAuthenticated) {
                auth.signoutRedirect()
            }
        },
        onSuccess: response => {
            if (response.data) {
                let oidcUser = getUser()
                localStorage.setItem('token', oidcUser?.access_token ?? '')
                localStorage.setItem('authtype', 'oidc')
                if(window.chrome && window.chrome.runtime) {
                    const port = chrome.runtime.connect('fkeikkmmcndnfbpehjjjafnbfhbopnoa')
                    port.postMessage({ action: 'CURRENT_USER', user: oidcUser })
                    port.disconnect()
                }
            }
        },
        staleTime: 1000 * 60 * 10
    })
}

export default useGetCurrentUser
