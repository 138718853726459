import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './Store/store';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { AuthProvider } from 'react-oidc-context';
import { OIDC_CONFIG } from './Utils/constants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
})
const rootElement = document.getElementById('root') as HTMLElement
rootElement.style.height = '100vh'

const root = ReactDOM.createRoot(rootElement)
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <React.StrictMode>
        <AuthProvider {...OIDC_CONFIG}>
          <App />
        </AuthProvider>
        {/* <ReactQueryDevtools /> Uncomment and import from @tanstack/react-query-devtools for react query debugging */}
      </React.StrictMode>
    </Provider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
