import { Code, PersonAddAltOutlined, PollOutlined } from '@mui/icons-material'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetModel } from '../../features/queries'
import DashboardLayout from '../../Layouts/DashboardLayout'
import { ROUTER_PATHS } from '../../Utils/constants'
import ModelDashAbout from './ModelDashAbout'
import ModelDashActions from './ModelDashActions'
import ModelDashUserShare from './ModelDashUserShare'
import ModelDashVersions from './ModelDashVersions'

const predictiveTabs = [
    { label: 'Insights', icon: <PollOutlined fontSize='small' />, subPath: '/insights' },
    { label: 'Predictive Feedback', icon: <PollOutlined fontSize='small' />, subPath: '/predfeedback' },
    { label: 'Configuration', icon: <Code fontSize='small' />, subPath: '/configuration' },
    { label: 'Users and Groups', icon: <PersonAddAltOutlined fontSize='small' />, subPath: '/permissions' },
]

const generativeTabs = [
    { label: 'Insights', icon: <PollOutlined fontSize='small' />, subPath: '/insights' },
    { label: 'Generative Feedback', icon: <PollOutlined fontSize='small' />, subPath: '/genfeedback' },
    { label: 'Configuration', icon: <Code fontSize='small' />, subPath: '/configuration' },
    { label: 'Users and Groups', icon: <PersonAddAltOutlined fontSize='small' />, subPath: '/permissions' },
]

interface ModelDashProps {
    children: React.ReactNode
    hideVersionSelect?: boolean
}

const ModelDash: React.FC<ModelDashProps> = ({ children, hideVersionSelect }) => {
    const { id } = useParams()
    const modelQuery = useGetModel(id)
    const navigate = useNavigate()
    const location = useLocation()

    const model = modelQuery.data
    if (modelQuery.isLoading || !model) {
        return null
    }

    const onTabSelect = (subPath: string) => {
        navigate(ROUTER_PATHS.model.replace(':id', model.id) + subPath)
    }

    const isTabSelected = (subPath: string) => {
        return location.pathname.includes(subPath)
    }

    const tabs = model.config.params.analytics && model.config.params.analytics[0].className === "OpenAIModel" ? generativeTabs : predictiveTabs

    return (
        <DashboardLayout title={model.config.params.name} subtitle={'Analyze model standings throughout its versions'}>
            <div className='flex px-6 mt-6'>
                {
                    tabs.map((tab, index) => {
                        return (
                            <button key={tab.label + index} onClick={onTabSelect.bind(this, tab.subPath)} className={`text-ai flex items-center px-4 py-2 rounded-t-md rounded-b-none cursor-pointer ${isTabSelected(tab.subPath) && 'font-bold bg-white'}`}>
                                {tab.label}
                            </button>
                        )
                    })
                }
            </div>
            <div className='flex gap-4 mx-6 p-6 bg-white rounded-md'>
                <div className={`flex-none ${hideVersionSelect ? 'w-full' : 'w-2/3'} inline-block float-left`}>
                    {children}
                </div>
                {!hideVersionSelect && (
                    <div className='flex-none w-1/3 flex flex-col min-h-[500px]'>
                        <div className='border-b border-ai-100'>
                            <ModelDashVersions model={model} />
                        </div>
                        <div className='border-b border-ai-100 py-7'>
                            <ModelDashAbout model={model} />
                        </div>
                        <div className='border-b border-ai-100 py-7'>
                            <ModelDashActions model={model} />
                        </div>
                        <div className='border-b border-ai-100 py-7'>
                            <ModelDashUserShare model={model} />
                        </div>
                    </div>
                )}
            </div>
        </DashboardLayout>
    )
}

export default ModelDash
