import React from "react";
import { RenderingProps } from "./RenderingSteps";
import { AddCircleOutline, Remove } from "@mui/icons-material";
import Checkbox from "../Checkbox";
import StyleParams from "./StyleParams";
import { getAnalyticPreviewKeys, getContainerIds } from "../../Utils/utils";
import Dropdown from "../Dropdown";
import ContentviewMessage from "../ContentviewMessage";
import { useLayout, useResponse } from "../../Store/Hooks";

const TableRenderingParams: React.FC<RenderingProps> = ({
    _model,
    stepIndex,
    onSaveChanges,
    onCancel,
}) => {

    const { setWarningResponse } = useResponse()
    const { configEditing } = useLayout()
    const [availableColumns, setAvailableColumns] = React.useState<string[]>([]);
    const [newKey, setNewKey] = React.useState("");
    const [params, setParams] = React.useState({
        id: "",
        tableName: "",
        description: "",
        containerId: "",
        predictionNameKey: "",
        predictionNameValues: [] as string[],
        predictionValueKey: "",
        yOffset: "",
        xOffset: "",
        canToggle: false,
        style: {
            table: {
                fontFamily: " Raleway, sans-serif",
                width: "100%",
                border: "1px solid lightgray",
                textAlign: "center",
                textTransform: "capitalize"
            },
            title: {
                fontWeight: "bold",
                marginBottom: "4px"
            },
            description: {
                fontWeight: "bold",
                marginBottom: "4px"
            },
            rowCells: {
                border: "1px solid lightgray",
                fontWeight: "normal",
                padding: "8px"
            },
            container: {
                border:"1px solid lightgray",
                margin:"8px 0",
                padding:"10px",
                fontSize:"14px",
                textAlign:"left",
                background:"#f3f3f3",
                fontFamily:"Raleway, sans-serif",
                borderRadius:"10px"
            },
            tableBody: {},
            headerRow: {
                border: "1px solid lightgray"
            },
            tableRows: {
                border: "1px solid lightgray"
            },
            tableHeader: {
                border: "1px solid lightgray",
                padding: "4px",
                textTransform: "capitalize",
                fontWeight: "bold"
            },
            headerCells: {
                border:"1px solid lightgray",
                padding:"8px",
                fontWeight:"bolder",
                backgroundColor:"white",
            }
        }
    });
    const [containerIds, setContainerIds] = React.useState<string[]>([]);
    const onContainerSelect = (event: any) => {
        const index = Number(event.target.value)
        setParams({...params, containerId: containerIds[index]});
    };

    React.useEffect(() => {
        if(_model.config.params.renderingSteps) {
            setContainerIds(getContainerIds(_model))
            if(stepIndex > -1) {
                setParams({...params, ..._model.config.params.renderingSteps[stepIndex].params})
            }
        }
    }, [stepIndex])

    React.useEffect(() => {
        if(configEditing.analyticsPreview) {
            setAvailableColumns(getAnalyticPreviewKeys(configEditing.analyticsPreview))
        }
    }, [configEditing.analyticsPreview])

    if (!_model) {
        return null;
    }

    const handleNewKey = () => {
        if(newKey.length === 0) return
        let _params = {...params}
        _params.predictionNameValues.push(newKey)
        setParams(_params)
    }

    const handleRemoveKey = (index: number) => {
        let _params = {...params}
        _params.predictionNameValues.splice(index, 1)
        setParams(_params)
    }

    const handleStyleChange = (element: any, attribute: any, value: string | null) => {
        // Fixes issues with read-only errors
        let _p: any = {
            ...params,
            style: {
                ...params.style,
                [element]: {
                    ...params.style[element as keyof typeof params.style]
                }
            }
        }
        if(value !== null) {
            _p.style[element][attribute] = value
        } else {
            delete _p.style[element][attribute]
        }
        setParams(_p)
    }

    const handleFieldsOnChange = (event: any) => {
        if(event.target.type === "checkbox") {
            setParams({ ...params, [event.target.name]: event.target.checked });
        }  else {
            setParams({ ...params, [event.target.name]: event.target.value });
        }
    };

    const onSubmit = () => {
        if(params.id.length === 0 ||
            params.predictionNameKey.length === 0 ||
            params.predictionNameValues.length === 0 ||
            params.predictionValueKey.length === 0) {
                setWarningResponse('Required Fields', `Please complete all required fields: ${["ID", "Prediction name key", "Prediction name values", "Prediction value key"].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`)
                return
        }
        if(params.containerId.length === 0 &&
            (params.yOffset.length === 0 || params.xOffset.length === 0)) {
                setWarningResponse('Required Fields', `If no container was specified, please fill out the following fields: ${["X Offset", "Y Offset"].join(', ')}`)
                return
        }
        onSaveChanges(params);
    };

    return (
        <div className="flex flex-col gap-4">
            <p className="font-bold text-ai-700">Table rendering</p>
            <div className="flex-1">
                { containerIds.length > 0 &&
                    <>
                        <label htmlFor="container-id" className="block text-ai text-[13px] px-2">Container ID</label>
                        <Dropdown selectedIndex={containerIds.indexOf(params.containerId)} placeholder="Select container" items={containerIds} handleDropdownChange={onContainerSelect}/>
                    </>
                }
                {
                    containerIds.length === 0 &&
                    <ContentviewMessage title="No Containers" message="Create containers for specific injections"/>
                }
            </div>
            <hr className="border-ai-200"/>
            <p className="font-bold text-ai-700">Identifiers</p>
            <div>
                <p className="px-2 text-[13px] text-ai">Table ID</p>
                <input
                    className="input w-full"
                    type="text"
                    placeholder={`table-id`}
                    name="id"
                    value={params.id ?? ""}
                    onChange={handleFieldsOnChange}
                />
            </div>
            <div className="flex gap-2">
                <div className="flex-1">
                    <p className="px-2 text-[13px] text-ai">Table name</p>
                    <input
                        className="input w-full"
                        type="text"
                        placeholder="Users"
                        name="tableName"
                        value={params.tableName ?? ""}
                        onChange={handleFieldsOnChange}
                    />
                </div>
                <div className="flex-1">
                    <p className="px-2 text-[13px] text-ai">Description</p>
                    <input
                        className="input w-full"
                        type="text"
                        placeholder="List of active users"
                        name="description"
                        value={params.description ?? ""}
                        onChange={handleFieldsOnChange}
                    />
                </div>
            </div>
            <hr className="border-ai-200"/>
            <p className="font-bold text-ai-700">Coordinates</p>
            <div className="flex gap-2">
                <div className="flex-1">
                    <p className="px-2 text-[13px] text-ai">X offset</p>
                    <input
                        className="input w-full"
                        type="text"
                        placeholder={`50`}
                        name="xOffset"
                        value={params.xOffset ?? ""}
                        onChange={handleFieldsOnChange}
                    />
                    <p className="px-2 text-[13px] text-ai-800">
                        Number is represented in %
                    </p>
                </div>
                <div className="flex-1">
                    <p className="px-2 text-[13px] text-ai">Y offset</p>
                    <input
                        className="input w-full"
                        type="text"
                        placeholder="50"
                        name="yOffset"
                        value={params.yOffset ?? ""}
                        onChange={handleFieldsOnChange}
                    />
                    <p className="px-2 text-[13px] text-ai-800">
                        Number is represented in %
                    </p>
                </div>
            </div>
            <hr className="border-ai-200"/>
            <p className="font-bold text-ai-700">Table Data Settings</p>
            <div className="flex gap-2">
                <div className="flex-1">
                    <label htmlFor="prediction-name-key" className="block px-2 text-[13px] text-ai">Column Key</label>
                    <input
                        className="input w-full"
                        id="prediction-name-key"
                        placeholder="name"
                        name="predictionNameKey"
                        value={params.predictionNameKey}
                        onChange={handleFieldsOnChange}
                    />
                </div>
                <div className="flex-1">
                    <label htmlFor="prediction-value-key" className="block px-2 text-[13px] text-ai">Value Key</label>
                    <input
                        className="input w-full"
                        id="prediction-value-key"
                        placeholder="value"
                        name="predictionValueKey"
                        value={params.predictionValueKey}
                        onChange={handleFieldsOnChange}
                    />
                </div>
            </div>
            <hr className="border-ai-200"/>
            <p className="font-bold text-ai-700">Table Data Columns (Prediction Name Values)</p>
            <div className="flex flex-col gap-3 rounded-sm border border-ai-200 bg-ai-100 p-2">
                <div className="flex items-center gap-2">
                    <div className="flex-1">
                        <label className="block px-2 text-[13px] text-ai">
                            Column Value
                        </label>
                        {availableColumns.length === 0 &&
                        <input
                            className="input w-full"
                            type="text"
                            placeholder="Visits"
                            value={newKey}
                            onChange={(e) => {
                                setNewKey(e.target.value);
                            }}
                        />}
                        {availableColumns.length > 0 &&
                        <Dropdown
                            items={availableColumns}
                            placeholder="Select key"
                            handleDropdownChange={(e: any) => {
                                const index = Number(e.target.value)
                                setNewKey(availableColumns[index]);
                            }} />
                        }
                    </div>
                    <div>
                        <button
                            className="btn-secondary flex items-center border border-ai p-3 text-[14px] font-bold"
                            onClick={handleNewKey}
                        >
                            <AddCircleOutline sx={{ fontSize: "16px" }} />
                            <span className="px-1">Add</span>
                        </button>
                    </div>
                </div>
                <div>
                    <table aria-label="datasources" className="border border-ai-200">
                        <thead className="border-b border-b-ai-200 bg-white text-left">
                            <tr>
                                <th className="w-1 px-4 py-2">
                                    <span className="text-[16px]  text-ai-800">
                                        #
                                    </span>
                                </th>
                                <th className="w-full">
                                    <span className="text-[16px]  text-ai-800">
                                        Value
                                    </span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="text-left">
                            {params.predictionNameValues.length === 0 && (
                                <tr>
                                    <td colSpan={4}>
                                        <div className="w-full p-6 text-center font-[16px] text-ai-800">
                                            There are no columns yet.
                                        </div>
                                    </td>
                                </tr>
                            )}

                            {params.predictionNameValues &&
                            params.predictionNameValues.map((source: any, index: number) => {
                                return (
                                    <tr key={source.predictionNameKey + index.toString()} className={"bg-ai-50"}>
                                        <td className="p-4">
                                            <span className=" text-ai-800">
                                                {index + 1 + "."}
                                            </span>
                                        </td>
                                        <td>
                                            <span className=" text-ai-800">
                                                {source}
                                            </span>
                                        </td>
                                        <td>
                                            <button className="btn-icon"
                                                onClick={() =>
                                                    handleRemoveKey(index)
                                                }
                                            >
                                                <Remove color="error" />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className="flex items-center text-ai">
                <span className="mr-2">
                    <Checkbox
                        name="canToggle"
                        onChange={handleFieldsOnChange}
                        checked={params.canToggle}
                    />
                </span>
                <p className="text-ai-800">Togglable step</p>
            </div> */}
            <hr className="border-ai-200"/>
            <p className="text-ai-700 font-bold">Styling</p>
            <div className="w-full bg-ai-50 flex">
                <div className="flex-1">
                    <StyleParams style={params.style} onStyleChange={handleStyleChange}/>
                </div>
                <div className="flex-1 w-4/5 bg-ai-100 p-2 flex flex-col gap-2">
                    <p className="text-ai-700 font-bold">Preview</p>
                    <hr className="border-ai-200"/>
                    <div className="flex-1 flex flex-col justify-center gap-2">
                        <div>
                            <div style={{...params.style.container as any}}>
                                <p style={{...params.style.title as any}}>
                                    {params.tableName}
                                </p>
                                <p style={{...params.style.description as any}}>
                                    {params.description}
                                </p>
                                <table style={{...params.style.table as any}}>
                                    <thead style={{...params.style.tableHeader as any}}>
                                        <tr style={{...params.style.headerRow as any}}>
                                            {params.predictionNameValues.map((source) => {
                                                return <th key={source + "-preview"} style={{...params.style.headerCells as any}}>{source}</th>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody style={{...params.style.tableBody as any}}>
                                        <tr style={{...params.style.tableRows as any}}>
                                            {!configEditing.analyticsPreview &&
                                            <>
                                                <td style={{...params.style.rowCells as any}}>Row 1 - Cell 1</td>
                                                <td style={{...params.style.rowCells as any}}>Row 1 - Cell 2</td>
                                            </>
                                            }
                                            {configEditing.analyticsPreview &&
                                            params.predictionNameValues.map((source) => {
                                                return <td key={source + "-value-preview"} style={{...params.style.rowCells as any}}>{configEditing.analyticsPreview && configEditing.analyticsPreview[source as keyof typeof configEditing.analyticsPreview]}</td>
                                            })
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    );
};

export default TableRenderingParams;
