import React, { useEffect, useState } from 'react'
import { AnalyticsProps } from '../AnalyticsSteps'
import { useAnalyticPreview } from '../../../features/mutations'
import { JSONTree } from 'react-json-tree'
import { JSONTreeTheme } from '../../../Utils/constants'
import { useLayout, useResponse } from '../../../Store/Hooks'
import { defaultParams } from './ReverseMLUtils'
import Checkbox from '../../Checkbox'
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'

const SalesforceReadParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {
    const { setWarningResponse } = useResponse()
    const previewMutation = useAnalyticPreview()
    const { configEditing } = useLayout()
    const [connectorName, setConnectorName] = useState('')
    const [scheduled, setScheduled] = useState(false)
    const [previewReady, setPreviewReady] = useState(false)
    const [params, setParams] = useState({
        ...defaultParams,
        connectorType: 'salesforce',
        connectorAction: 'read',
        authenticationType: 'UPCredentials',
    })

    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                const _params = _model.config.params.analytics[stepIndex].params
                setConnectorName(_model.config.params.analytics[stepIndex].label)
                setParams({
                    ...params,
                    ..._params,
                })
                if (_params.schedule?.length > 0) {
                    setScheduled(true)
                }
            }
        }
    }, [stepIndex])

    useEffect(() => {
        if (
            params.connectorType === 'salesforce' &&
            ((params.authenticationType === 'UPCredentials' &&
                params.dbUser.length > 0 &&
                params.password.length > 0 &&
                params.token.length > 0 &&
                params.soql.length > 0) ||
                (params.authenticationType === 'connectedApp' &&
                    params.clientId.length > 0 &&
                    params.clientSecret.length > 0 &&
                    params.soql.length > 0))
        ) {
            setPreviewReady(true)
        } else {
            setPreviewReady(false)
        }
    }, [params])

    if (!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const fetchPreviewData = (e: any) => {
        e.preventDefault()
        console.log('=== params ===')
        console.log(params)
        if (previewReady) {
            previewMutation.mutate({
                step: { className: 'ReverseMLWorkflow', params },
            })
        }
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onScheduledChange = (e: any) => {
        if (!e.target.checked) {
            setParams({ ...params, schedule: '' })
        }
        setScheduled(!scheduled)
    }

    const handleScheduleOnChange = (value: string) => {
        setParams({ ...params, schedule: value })
    }

    const onSubmit = () => {
        let _params = { ...params }
        if (
            connectorName.length === 0 ||
            _params.soql.length === 0 ||
            _params.host.length === 0 ||
            _params.dbUser.length === 0 ||
            _params.password.length === 0 ||
            _params.clientId.length === 0 ||
            _params.clientSecret.length === 0
        ) {
            setWarningResponse(
                'Required Fields',
                `Please complete all required fields: ${[
                    'Name',
                    'Host',
                    'User',
                    'Password',
                    'Token',
                    'Client Id',
                    'Client Secret',
                    'SOQL Query',
                ].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
            )
            return
        }
        onSaveChanges(connectorName, _params)
    }

    return (
        <div className='flex flex-col gap-4'>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name*</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                />
            </div>
            <hr className='border-ai-200' />
            <div>
                <label className='px-2 text-[13px] text-ai'>Host*</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleFieldsOnChange}
                    value={params.host}
                    placeholder='https://your-domain.my.salesforce.com'
                    name='host'
                />
            </div>
            <div>
                <label className='px-2 text-[13px] text-ai'>User</label>
                <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.dbUser ?? ''} name='dbUser' />
            </div>
            <div>
                <label className='px-2 text-[13px] text-ai'>Password</label>
                <input
                    className='input w-full'
                    type={'password'}
                    onChange={handleFieldsOnChange}
                    value={params.password ?? ''}
                    name='password'
                />
            </div>
            <div>
                <label className='px-2 text-[13px] text-ai'>Token</label>
                <input className='input w-full' type={'password'} onChange={handleFieldsOnChange} value={params.token ?? ''} name='token' />
            </div>
            <div>
                <label className='px-2 text-[13px] text-ai'>Client ID</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleFieldsOnChange}
                    value={params.clientId ?? ''}
                    name='clientId'
                />
            </div>
            <div>
                <label className='px-2 text-[13px] text-ai'>Client Secret</label>
                <input
                    className='input w-full'
                    type={'password'}
                    onChange={handleFieldsOnChange}
                    value={params.clientSecret ?? ''}
                    name='clientSecret'
                />
            </div>
            <div>
                <label className='px-2 text-[13px] text-ai'>SOQL Query</label>
                <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.soql ?? ''} name='soql' />
            </div>
            <hr className='border-ai-200' />
            <div className='flex flex-1 gap-2'>
                <Checkbox checked={scheduled} onChange={onScheduledChange} />
                <label className='block px-2 text-ai'>Schedule ETL?</label>
            </div>
            {scheduled && (
                <div className='w-full'>
                    <Cron value={params.schedule} setValue={handleScheduleOnChange} />
                </div>
            )}
            <hr className='border-ai-200' />
            <div>
                <button disabled={!previewReady} className='btn-secondary py-1 px-2 text-sm' onClick={fetchPreviewData}>
                    Fetch Preview Data
                </button>
            </div>
            <div className='flex w-full flex-col items-center justify-center'>
                {previewMutation.isLoading && <p className='font-[20px] text-ai'>Fetching data preview...</p>}
                {previewMutation.isError && (
                    <p className='font-[20px] text-ai'>Could not retrieve preview of the data - Review connector parameters</p>
                )}
                {!previewMutation.isLoading && configEditing.analyticsPreview && (
                    <div className='w-full flex-1 bg-white p-4'>
                        <p className='font-bold text-ai-700'>Data Sample Preview: </p>
                        <JSONTree
                            shouldExpandNode={() => {
                                return true
                            }}
                            theme={JSONTreeTheme}
                            data={configEditing.analyticsPreview ?? {}}
                        />
                    </div>
                )}
            </div>
            {/* footer */}
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button className='btn-secondary border-none bg-transparent text-[#FF0000]' onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default SalesforceReadParams
