import { AuthProviderProps } from "react-oidc-context";
import { AIRModel, ModelConfigRoot } from "../features/types";
import { env } from "../env";
import { User, WebStorageStateStore } from "oidc-client-ts";

export const JSONTreeTheme = {
    scheme: 'google',
    author: 'seth wright (http://sethawright.com)',
    base00: '#1d1f21',
    base01: '#282a2e',
    base02: '#373b41',
    base03: '#969896',
    base04: '#b4b7b4',
    base05: '#c5c8c6',
    base06: '#e0e0e0',
    base07: '#ffffff',
    base08: '#CC342B',
    base09: '#F96A38',
    base0A: '#FBA922',
    base0B: '#198844',
    base0C: '#3971ED',
    base0D: '#3971ED',
    base0E: '#A36AC7',
    base0F: '#3971ED'
};

export const listOptions = {
    pageSize: 15
}

export const ROUTER_PATHS = Object.freeze({
    landing: '/',
    login: '/login',
    models: '/models',
    workspace: '/workspace',
    users: '/users',
    groups: '/groups',
    workspaceEdit: '/workspace/edit/:id',
    create: '/create',
    model: '/models/:id',
    group: '/groups/:id',
    credentials: '/machine-learning/credentials',
    tuning: '/machine-learning/tuning',
    tuningCreate: '/machine-learning/tuning/new',
    serving: '/machine-learning/serving',
    servingCreate: '/machine-learning/serving/new',
})

export const stageHexColorMap = {
    staging: '#f0a000',
    experimental: '#f00540',
    production: '#0048D9'
}

export const stageMUIColorMap = {
    staging: 'warning',
    experimental: 'error',
    production: 'primary'
}

export const PREPROCESSING_STEPS = Object.freeze({
    ImagePreprocessor: [
        {
            label: 'Add Value',
            value: 'Add'
        },
        {
            label: 'Subsctract Value',
            value: 'Subtract'
        },
        {
            label: 'Multiply Value',
            value: 'Multiply'
        },
        {
            label: 'Divide Value',
            value: 'Divide'
        },
        {
            label: 'Convert To Color',
            value: 'ConvertToColor'
        },
        {
            label: 'Resize',
            value: 'Resize'
        },
    ],
    TextPreprocessor: [
        {
            label: 'Tokenize',
            value: 'Tokenize'
        },
        {
            label: 'Remove Characters',
            value: 'RemoveCharacters'
        },
        {
            label: 'Convert To Case',
            value: 'ConvertToCase'
        },
        {
            label: 'Convert To Vocabulary',
            value: 'ConvertToVocabulary'
        },
        {
            label: 'Pad Sequences',
            value: 'PadSequences'
        },
    ],
    TabularPreprocessor: []
})

export const EMPTY_AIR_MODEL_CONFIG: ModelConfigRoot = {
    className: "ModelConfiguration",
    params: {
        name: "",
        version: "1",
        description: "",
        author: "",
        stage: "experimental",
        mlflowUri: null,
        mlflowUser: null,
        mlflowToken: null,
        harvestingSteps: [],
        preprocessingSteps: [],
        postprocessingSteps: [],
        analytics: [],
        renderingSteps: [],
        feedbackSteps: [],
        urls: "*"
    }
}

export const EMPTY_AIR_MODEL: AIRModel = {
    id: "new",
    name: "",
    description: "",
    type: "text",
    version: "1",
    config: EMPTY_AIR_MODEL_CONFIG,
    createdAt: new Date(),
    modifiedAt: new Date(),
    airjsDefault: false,
    isOwner: true,
    storageResourceMeta: [],
    history: []
}

export const OIDC_CONFIG: AuthProviderProps = {
    //(string): The URL of the OIDC provider.
    authority: env.REACT_APP_AUTH_URL,
    //(string): Your client application's identifier as registered with the OIDC provider.
    client_id: env.REACT_APP_CLIENT_ID,
    //(string): The URI of your client application to receive a response from the OIDC provider.
    redirect_uri: env.REACT_APP_REDIRECT_URI,
    //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    automaticSilentRenew: true,
    //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    loadUserInfo: true,
    //(string): The URL for the page containing the code handling the silent renew.
    silent_redirect_uri: env.REACT_APP_SILENT_REDIRECT_URI,
    //(string): The OIDC post-logout redirect URI.
    post_logout_redirect_uri: env.REACT_APP_LOGOFF_REDIRECT_URI,
    //(string, default: 'openid'): The scope being requested from the OIDC provider.
    scope: "openid profile email",
        onSigninCallback: (_user: User | void): void => {
        console.log(`[onSigninCallback] ${window.location.href}`);
        window.history.replaceState({}, document.title, window.location.pathname)
    },
    userStore: new WebStorageStateStore({
        store: localStorage
    })
}