import { useQuery } from "@tanstack/react-query"
import {AIRModel, WorkspaceQueryParams} from "../types"
import Qs from 'qs'
import axios from "axios"
import { endpoints } from "../endpoints"

const useWorkspace = (params: WorkspaceQueryParams) => {
    return useQuery(["workspace", {...params}], async () => {
        const token = localStorage.getItem("token");
        const authtype = localStorage.getItem("authtype");
        if (token && authtype) {
            const options = {
                headers: {
                    authtype,
                    Authorization: `Bearer ${token}`,
                },
                params,
                paramsSerializer: (params: any) => {
                    return Qs.stringify(params)
                }
            };
            const response = await axios.get<{ data: { models: AIRModel[] } }>(endpoints.models, options);
            return response.data.data.models
        }
    }, {
        initialData: [],
        keepPreviousData: true
    })
}

export default useWorkspace
