import React from "react"
import TextHarvesterParams from "./TextHarvesterParams"
import ImageHarvesterParams from "./ImageHarvesterParams"
import InputHarvesterParams from "./InputHarvesterParams"
import { validateRequiredFields } from "../../Utils/utils"
import { useResponse } from "../../Store/Hooks"
import QueryParameterHarvesterParams from "./QueryParameterHarvesterParams"
import { AIRModel, BasicStepsProps } from "../../features/types"
import Dropdown from "../Dropdown"

export interface HarvesterProps {
    _model: AIRModel,
    onSaveChanges: (params: any) => void,
    onRemoveClass: () => void
}

const harvesterOptions = [
    {
        option: "Text Harvester",
        className: "TextHarvester",
        required: ['how']
    },
    {
        option: "Image Harvester",
        className: "ImageHarvester",
        required: ['how']
    },
    {
        option: "Input Harvester",
        className: "InputHarvester",
        required: ['inputType']
    },
    {
        option: "Query Parameters Harvester",
        className: "QueryParameterHarvester",
        required: []
    }
]

const harvesterClassComponents = {
    TextHarvester: TextHarvesterParams,
    ImageHarvester: ImageHarvesterParams,
    InputHarvester: InputHarvesterParams,
    QueryParameterHarvester: QueryParameterHarvesterParams
}

type ObjectKey = keyof typeof harvesterClassComponents;

const HarvesterSteps: React.FC<BasicStepsProps> = ({ _model, onConfigChange }) => {

    const { setWarningResponse } = useResponse()
    const [optionClass, setOptionClass] = React.useState('')
    const [selectedOpt, setSelectedOpt] = React.useState<any>(null)


    React.useEffect(() => {
        if (_model &&  _model.config.params.harvestingSteps && _model.config?.params?.harvestingSteps?.length > 0) {
            setOptionClass(_model.config?.params?.harvestingSteps[0].className)
            const selected = (harvesterOptions.filter((a) => {
                if(_model.config?.params?.harvestingSteps) {
                    return a.className === _model.config?.params?.harvestingSteps[0].className
                }
            }))[0]
            setSelectedOpt(selected)
        }
    }, [_model])

    if (_model === null) {
        return null
    }

    const onOptionChange = (event: any) => {
        const selectedIndex = Number(event.target.value)
        setOptionClass(harvesterOptions[selectedIndex].className)
        const selected = harvesterOptions[selectedIndex]
        setSelectedOpt(selected)
    }

    const onSaveChanges = (params: any) => {
        if (optionClass !== null) {
            let harvestingSteps = [{ className: optionClass, params }]
            let config = {
                ..._model.config,
                params: {
                    ..._model.config.params,
                    harvestingSteps
                }
            }
            if (validateRequiredFields(params, selectedOpt.required)) {
                onConfigChange(config)
            } else {
                setWarningResponse('Required Fields', `Please complete all required fields: ${selectedOpt.required.join(', ').replace(/, (?=[^,]*$)/, ' & ')}`)
            }
        }
    }

    const onRemoveClass = () => {
        const config = {
            ..._model.config,
            params: {
                ..._model.config.params,
                harvestingSteps: null
            }
        }
        if(onConfigChange) {
            onConfigChange(config)
        }
        setOptionClass('')
        setSelectedOpt(null)
    }

    const renderParamsComponent = () => {
        const Component = harvesterClassComponents[optionClass as ObjectKey]
        if (Component) {
            return <Component _model={_model} onSaveChanges={onSaveChanges} onRemoveClass={onRemoveClass} />
        }
        return null
    }

    const selectedClassIndex = optionClass.length > 0 ? harvesterOptions.findIndex((a) => a.className === optionClass) : -1

    return (
        <div>
            <p className="text-ai-700 text-[16px] font-bold mb-2">Harvest class</p>
            <Dropdown selectedIndex={selectedClassIndex} placeholder="Choose harvesting class" items={harvesterOptions.map((opt) => opt.option) as string[]} handleDropdownChange={onOptionChange} size='w-1/2'/>
            <div className="my-6">
                {
                    renderParamsComponent()
                }
            </div>
        </div>
    )
}

export default HarvesterSteps