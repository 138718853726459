import { useQuery } from "@tanstack/react-query"
import axios from "axios";
import { endpoints } from "../endpoints";
import { AIRModel } from "../types";

const useGetModel = (id?: string) => {
    return useQuery(["models", id], async () => {
        const token = localStorage.getItem("token");
        const authtype = localStorage.getItem("authtype");
        if (token && authtype) {
            const options = {
                headers: {
                    authtype,
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await axios.get<{data: AIRModel}>(`${endpoints.models}/${id}`, options);
            return response.data.data;
        }

    }, {
        enabled: !!id,
        staleTime: 1000 * 60 * 60 * 24 // 24hr client cache
    })
}

export default useGetModel
