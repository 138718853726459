import React, { useEffect, useState } from 'react'
import { AnalyticsProps } from '../AnalyticsSteps'
import { useAnalyticPreview } from '../../../features/mutations'
import { JSONTree } from 'react-json-tree'
import { JSONTreeTheme } from '../../../Utils/constants'
import { useLayout, useResponse } from '../../../Store/Hooks'
import { defaultParams } from './ReverseMLUtils'
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import Checkbox from '../../Checkbox'


const SnowflakeReadParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {
    const { setWarningResponse } = useResponse()
    const previewMutation = useAnalyticPreview()
    const { configEditing } = useLayout()
    const [connectorName, setConnectorName] = useState('')
    const [previewReady, setPreviewReady] = useState(false)
    const [passwordEditable, setPasswordEditable] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState({ password: '' })
    const [scheduled, setScheduled] = useState(false)
    const [params, setParams] = useState({ ...defaultParams, connectorType: 'snowflake', connectorAction: 'read' })


    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                const _params = _model.config.params.analytics[stepIndex].params
                setConnectorName(_model.config.params.analytics[stepIndex].label)
                setParams({
                    ...params,
                    ..._params,
                })
                if(_params.schedule?.length > 0) {
                    setScheduled(true)
                }
            }
        }
    }, [stepIndex])

    useEffect(() => {
        if (
            params.connectorType === 'snowflake' &&
            params.account.length > 0 &&
            params.organization.length > 0 &&
            params.dbUser.length > 0 &&
            params.dbName.length > 0 &&
            params.password.length > 0 &&
            params.schema.length > 0 &&
            params.warehouse.length > 0 &&
            params.query.length > 0
        ) {
            setPreviewReady(true)
        } else {
            setPreviewReady(false)
        }
    }, [params])

    if (!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const fetchPreviewData = (e: any) => {
        e.preventDefault()
        if (previewReady) {
            previewMutation.mutate({
                step: { className: 'ReverseMLWorkflow', params },
            })
        }
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const handleConfirmPasswordFieldOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword({ ...confirmPassword, [event.target.name]: event.target.value })
    }

    const handlePasswordEdit = () => {
        setPasswordEditable(true)
        let _params = { ...params }
        _params.password = ''
        setParams({
            ..._params,
        })
    }

    const onScheduledChange = (e: any) => {
        if (!e.target.checked) {
            setParams({ ...params, schedule: '' })
        }
        setScheduled(!scheduled)
    }

    const handleScheduleOnChange = (value: string) => {
        setParams({ ...params, schedule: value })
    }

    const onSubmit = () => {
        let _params = { ...params }
        if (
            connectorName.length === 0 ||
            _params.account.length === 0 ||
            _params.warehouse.length === 0 ||
            _params.organization.length === 0 ||
            _params.dbName.length === 0 ||
            _params.schema.length === 0 ||
            _params.query.length === 0 ||
            _params.dbUser.length === 0 ||
            _params.role.length === 0 ||
            _params.password.length === 0
        ) {
            setWarningResponse(
                'Required Fields',
                `Please complete all required fields: ${[
                    'Name',
                    'Account ID',
                    'Organization',
                    'Warehouse',
                    'Database',
                    'Schema',
                    'Query',
                    'Schedule',
                    'Username',
                    'Password',
                    'Role',
                ].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
            )
            return
        }
        if (
            (_model.id === 'new' && _params.password !== confirmPassword.password) ||
            (_model.id !== 'new' && passwordEditable && _params.password !== confirmPassword.password)
        ) {
            setWarningResponse(
                'Password Validation Error',
                `Ensure the following fields match: ${['Password', 'Confirm Password'].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`,
            )
            return
        }

        if (_params.password === confirmPassword.password) {
            _params.password = params.password
        } else {
            _params.password = ''
        }
        onSaveChanges(connectorName, _params)
    }

    return (
        <div className='flex flex-col gap-4'>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                />
            </div>
            <div className='grid grid-cols-9 gap-2'>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Account ID*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.account ?? ''}
                        name='account'
                    />
                </div>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Warehouse*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.warehouse ?? ''}
                        name='warehouse'
                    />
                </div>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Organization*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.organization ?? ''}
                        name='organization'
                    />
                </div>
            </div>
            <div className='grid grid-cols-9 gap-2'>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Database*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.dbName ?? ''}
                        name='dbName'
                    />
                </div>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Schema*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.schema ?? ''}
                        name='schema'
                    />
                </div>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Query*</label>
                    <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.query ?? ''} name='query' />
                </div>
            </div>
            <div className='grid grid-cols-7 gap-2'>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Username*</label>
                    <input
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.dbUser ?? ''}
                        name='dbUser'
                    />
                </div>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Role*</label>
                    <input className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.role ?? ''} name='role' />
                </div>
            </div>
            <div className='grid grid-cols-6 gap-2'>
                <div className='relative top-1 col-span-full xl:col-span-3'>
                    <div className='flex justify-between'>
                        <label className='px-2 text-[13px] text-ai'>Password*</label>
                        {_model.id !== 'new' && !passwordEditable && (
                            <label
                                className='cursor-pointer px-2 text-[13px] text-ai-300 transition hover:text-ai hover:underline'
                                onClick={handlePasswordEdit}
                            >
                                Edit
                            </label>
                        )}
                    </div>
                    <input
                        className='input w-full'
                        type={'password'}
                        onChange={e => {
                            if (params.password.length < 1 && confirmPassword.password.length > 0) {
                                setConfirmPassword({ password: '' })
                            }
                            handleFieldsOnChange(e)
                        }}
                        value={params.password ?? ''}
                        name='password'
                        disabled={_model.id !== 'new' && !passwordEditable}
                    />
                </div>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Confirm Password*</label>
                    <input
                        className='input w-full'
                        type={'password'}
                        onChange={handleConfirmPasswordFieldOnChange}
                        value={params.password ? confirmPassword.password : ''}
                        disabled={params.password.length < 1 || (_model.id !== 'new' && !passwordEditable)}
                        name='password'
                    />
                </div>
            </div>
            <hr className='border-ai-200' />
            <div className='flex flex-1 gap-2'>
                <Checkbox checked={scheduled} onChange={onScheduledChange} />
                <label className='block px-2 text-ai'>Schedule ETL?</label>
            </div>
            {scheduled && (
                <div className='w-full'>
                    <Cron value={params.schedule} setValue={handleScheduleOnChange} />
                </div>
            )}
            <hr className='border-ai-200' />
            <div>
                <button disabled={!previewReady} className='btn-secondary py-1 px-2 text-sm' onClick={fetchPreviewData}>
                    Fetch Preview Data
                </button>
            </div>
            <div className='flex w-full flex-col items-center justify-center'>
                {previewMutation.isLoading && <p className='font-[20px] text-ai'>Fetching data preview...</p>}
                {previewMutation.isError && (
                    <p className='font-[20px] text-ai'>Could not retrieve preview of the data - Review connector parameters</p>
                )}
                {!previewMutation.isLoading && configEditing.analyticsPreview && (
                    <div className='w-full flex-1 bg-white p-4'>
                        <p className='font-bold text-ai-700'>Data Sample Preview: </p>
                        <JSONTree
                            shouldExpandNode={() => {
                                return true
                            }}
                            theme={JSONTreeTheme}
                            data={configEditing.analyticsPreview ?? {}}
                        />
                    </div>
                )}
            </div>
            {/* footer */}
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button className='btn-secondary border-none bg-transparent text-[#FF0000]' onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default SnowflakeReadParams
